import React from 'react'
import { FONT_SIZES } from './Templates/constants'
import styles from './navigator.module.css'

export default function Navigator() {
    return (
        <div id="navigator" className={styles.navigator} >
            <div className={FONT_SIZES.CUSTOM_FONT_STANDARD}>
                <div style={{ padding: "3vmax", color: "#FFF" }}>
                    <p>Welcome to my website!</p>
                    <div>
                        <p>
                            {/* I'm a Software Engineer at Stripe.<br /> */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
