import React from 'react'
import styles from './header.module.css'
import pic from '../images/pic.jpg'
import LinkButton from './Templates/LinkButton'
import '../styles/text-styles.css'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import { faAngleDown} from '@fortawesome/free-solid-svg-icons'
import TextChrByChr from './Templates/TextChrByChr'
import ClickButton from './Templates/ClickButton'

export default function header() {
  return (
    <div>
      <div className={styles.outer}>
        <div className={styles.inner}>
            <TextChrByChr text="Hello, I'm" />

            <motion.div
              style={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              transition={{
                delay: 1.75,
              }}
            >
              <img src={pic} alt="Me" style={{maxHeight:"200px", margin:"0", borderRadius: "50%"}}/>

              <h2 style={{marginTop:"1%", marginBottom:"1vh", color: "white", fontFamily:"Antic Slab"}}>Sujay R Subramanian</h2>
              <br />

              <div>
                <LinkButton href="https://github.com/rssujay">GitHub</LinkButton>
                <LinkButton href="https://www.linkedin.com/in/sujay-r-subramanian">Linkedin</LinkButton>
              </div>
            </motion.div>
            <div className={styles.background}></div>
        </div>
        <motion.p
          className="responsive-small"
          style={{color: "white", fontFamily: "oxanium", position:"relative", textAlign:"center", visibility:"hidden", opacity: 0}}
          animate={{ visibility: "visible", opacity: 1 }}
          transition={{ 
              type: "spring",
              stiffness: 100,
              delay: 2.25,
              duration: 3,
            }}
          >
          <ClickButton onClick={() => scrollTo('#navigator')}>
            Scroll Down <FontAwesomeIcon icon={faAngleDown} />
          </ClickButton>
        </motion.p>
      </div>
    </div>

  )
}
