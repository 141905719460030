import React from 'react'
import { motion } from 'framer-motion'

export default function ClickButton(props) {

    const initialStyle = {
        textTransform: "uppercase",
        fontFamily: 'Oxanium',
        background: "rgba(45,49,66,0.3)",
        border: "0.12rem solid #BFC0C0",
        padding: "max(1vmax, 5px) 2vmax",
        margin: "max(1vmax, 5px)",
        marginTop: "0",
        color: "rgb(224, 231, 231)",
        letterSpacing: "1.5px",
        opacity: 1.0,
    }

    const hoveredStyle = {
        cursor: "pointer",
        textDecoration: "none",
        backgroundColor: "#799bbd",
        color: "rgb(230, 231, 235)",
        textShadow: "0px 0px 8px rgb(255, 255, 255)",
        boxShadow: "0px 0px 8px rgb(255, 255, 255)",
        transition: {
            duration: 0.2,
            ease: "anticipate"
        },
    }

    const tappedStyle = {
        textDecoration: "none",
        backgroundColor: "#6082A4",
        color: "rgb(230, 231, 235)",
        boxShadow: "inset 0px 0px 1px rgb(255, 255, 255)",
        transition: {
            duration: 0.2,
            ease: "anticipate"
        },
    }

    return (
        <motion.button
            style={initialStyle}
            whileHover={hoveredStyle}
            whileTap={tappedStyle}
            onClick={props.onClick}
        >
            {props.children}
        </motion.button>
    )
}
