import React from 'react'
import Header from '../header'
import Navigator from '../Navigator'

export default function Integrator() {
    return (
    <div >
        <Header />
        <Navigator />
    </div>
    )
}
