import React from "react"
import '../styles/uikit-3.4.3/css/uikit.min.css'
import SEO from "../components/seo"
import Integrator from "../components/Landing/Integrator"
import Footer from "../components/Footer"

import loadable from '@loadable/component'
const TabView = loadable(() => import('../components/tabview'))

const IndexPage = () => (
  <div>
    <SEO title="Home" />
    <Integrator />
    <TabView />
    <Footer />
  </div>
)

export default IndexPage
