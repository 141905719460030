import React from 'react'
import { motion } from 'framer-motion'

export default function TextChrByChr(props) {
    const text = props.text

    const item = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
      }

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
            staggerChildren: 0.15
            }
        }
    }
    
    return (
        <motion.h1
            style={{marginTop:"1%", marginBottom:"1vh", color: "white", fontFamily:"Antic Slab"}}
            variants={container}
            initial="hidden"
            animate="show"
        >
            {
                text.split('').map((letter) => {
                return (
                    <motion.span variants={item}>
                        {letter}
                    </motion.span>
                    )
                })
            }
        </motion.h1>
    )
}
