export const FONT_SIZES = {
    FONT_STANDARD: "responsive-standard",
    CUSTOM_FONT_STANDARD: "responsive-standard-sp",
    FONT_LARGE: "responsive-large",
    FONT_LARGER: "responsive-larger",
    FONT_SMALL: "responsive-small",
}

export const LAYOUTS = {
    LR_MARGIN_SMALL: "10%",
    LR_MARGIN_STANDARD: "18%",
    LR_MARGIN_LARGE: "20%",
    SMALL_BREAKPOINT: 800,
}
